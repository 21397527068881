

.led-green {
    margin: 0 auto;
    width: 15px;
    height: 15px;
    background-color: #ABFF00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
}


.led-red {
    margin: 0 auto;
    width: 15px;
    height: 15px;
    background-color: #F00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
    -webkit-animation: blinkRed 0.5s infinite;
    -moz-animation: blinkRed 0.5s infinite;
    -ms-animation: blinkRed 0.5s infinite;
    -o-animation: blinkRed 0.5s infinite;
    animation: blinkRed 0.5s infinite;
}



.led-orange {
    margin: 0 auto;
    width: 15px;
    height: 15px;
    background-color: #FF0;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 12px;
    -webkit-animation: blinkYellow 1s infinite;
    -moz-animation: blinkYellow 1s infinite;
    -ms-animation: blinkYellow 1s infinite;
    -o-animation: blinkYellow 1s infinite;
    animation: blinkYellow 1s infinite;
}
